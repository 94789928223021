import React, { useEffect, useRef, useState } from "react";
import { graphql } from "gatsby";
import { WmkSeo } from "wmk-seo";
import { PageQuery } from "../fragments/NodePageFields";
import { SiteMetaDataFields } from "../fragments/NodeSiteMetadataFields";
import { Img } from "wmk-image";
import Hero from "../components/ui/Hero";
import CtaBlock from "../components/ui/CtaBlock";
import { RichText, RichTextReact } from "wmk-rich-text";
import { options } from "../components/Blocks/richTextOptions";
import styled from "styled-components";
import HubspotForm from "react-hubspot-form";
import { Col, Container, Row } from "react-bootstrap";
import { colors } from "../components/ui/colors";
import { Typography } from "../components/ui/Typography";

const FormWrapper = styled.div`
  // span {
  //   color: ${colors.lightBlue.getHex()} !important;
  // }
  // .hs-form-field {
  //   label {
  //     color: ${colors.red.getHex()};
  //     font-family: Rajdhani;
  //     font-weight: 600;
  //     font-size: 18px;
  //     text-transform: uppercase;
  //   }
  //   .hs-input {
  //     width: 100%;
  //     border: 3px solid #e4effa;
  //   }
  // }
`;

const RichTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  .rich-text-video-wrap {
    display: flex;
    justify-content: center;
    margin: 2rem 0;
  }
  @media screen and (max-width: 767px) {
    .rich-text-video-wrap {
      order: -1;
      margin-top: 0;
    }
  }
`;

const FormContainer = styled(Container)`
  padding-top: 2rem;
  padding-bottom: 2rem;
`;

const PageNode = ({
  data
}: {
  data: { page: PageQuery; site: SiteMetaDataFields };
}) => {
  const [viewWidth, setViewWidth] = useState(0);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleResize = () => {
    const winWidth = window.innerWidth;
    setViewWidth(winWidth);
  };

  const image = data.page.hero?.backgroundImage
    ? new Img(data.page.hero.backgroundImage)
    : undefined;
  let og = ``;
  let twitter = ``;
  if (image && data.page.hero) {
    image.addCrop("ogImage", data.page.hero.backgroundImage.ogImage);
    image.addCrop("twitter", data.page.hero.backgroundImage.twitterImage);
    og = image.url("ogImage");
    twitter = image.url("twitter");
  }
  const ctaData = data.page.callToAction;
  return (
    <>
      <WmkSeo.Meta
        siteTitle={data.site.siteMetadata.title}
        baseUrl={data.site.siteMetadata.baseUrl}
        title={data.page.title}
        ogImageUrl={og}
        slug={data.page.slug}
        twitterImageUrl={twitter}
      />
      {data.page.hero && (
        <Hero
          heroData={data.page.hero}
          isThankYou={data.page.slug === "thank-you"}
        />
      )}
      {data.page?.slug === "contact-us" ||
      data.page?.slug === "get-involved" ? (
        <FormContainer>
          <Row>
            <Col>
              <Typography.H2
                style={{ color: colors.red.getHex(), margin: "1.5rem 0" }}>
                {data.page.title}
              </Typography.H2>
            </Col>
          </Row>
          <Row>
            <Col>
              <Container
                style={{ borderLeft: `5px solid ${colors.red.getHex()}` }}>
                <Row>
                  <Col md={6} sm={12}>
                    <Typography.Copy
                      style={{
                        color: colors.blue.getHex(),
                        marginBottom: "1rem"
                      }}>
                      {data.page.formDescription}
                    </Typography.Copy>
                    <FormWrapper>
                      <HubspotForm
                        portalId={"22283803"}
                        formId={
                          data.page?.slug === "contact-us"
                            ? "e4c99ada-1e2f-4e11-98f9-ce6b9fe98ba9"
                            : "4a54c6f0-d610-4efd-a186-1064a037af59"
                        }
                        onSubmit={() => {}}
                        onReady={() => {}}
                      />
                    </FormWrapper>
                  </Col>
                  {viewWidth > 767 && (
                    <Col md={6} sm={12}>
                      <RichTextWrap>
                        <RichTextReact
                          content={new RichText(data.page.content)}
                          options={options}
                        />
                      </RichTextWrap>
                    </Col>
                  )}
                </Row>
              </Container>
            </Col>
          </Row>
        </FormContainer>
      ) : (
        <RichTextWrap>
          <RichTextReact
            content={new RichText(data.page.content)}
            options={options}
          />
        </RichTextWrap>
      )}
      {ctaData && (
        <CtaBlock
          headline={ctaData.headline}
          subheader={ctaData.subhead}
          copy={ctaData.copy}
          link={ctaData.action}
        />
      )}
    </>
  );
};

export default PageNode;

export const query = graphql`
  query pageQuery($slug: String!) {
    page: contentfulPage(slug: { eq: $slug }) {
      ...NodePageFields
    }
    site {
      siteMetadata {
        title
        baseUrl
      }
    }
  }
`;
